.label {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 30px;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 6px;
  font-family: Heebo, sans-serif;

  &:last-child {
    margin-bottom: 20px;
  }

  &:hover .indicator {
    border: 2px solid #bbb;
  }

  &.checked .indicator {
    border: 2px solid #82bc2f;

    &::after {
      transform: translateY(-50%) scale(1);
    }

    &:hover .indicator {
      border: 2px solid #82bc2f;
    }
  }
}

.indicator {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #dedede;

  &::after {
    content: '';
    position: absolute;
    left: 2px;
    top: 50%;
    width: 12px;
    height: 12px;
    transition: transform .1s ease-out;
    transform: translateY(-50%) scale(0);
    display: block;
    background-color: #82bc2f;
    border-radius: 50%;
  }
}
