.form {
  padding: 20px;
  background-color: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 460px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.header {
  margin-bottom: 30px;
}

.body {
  margin-bottom: 20px;
}

.footer {
  display: flex;
  justify-content: center;

  @media (min-width: 690px) {
    justify-content: flex-end;
  }
}

.subtitle {
  color: #222;
  font-size: 17px;
  font-weight: 500;

  @media (max-width: 460px) {
    br {
      display: none;
    }
  }
}

.close {
  background: transparent;
  border: none;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }
}
