.textarea {
  border: 1px solid #dedede;
  border-radius: 2px;
  width: 100%;
  font-family: Heebo;
  font-size: 15px;
  line-height: 1.35;
  color: #222;
  padding: 2px 5px;

  &:focus,
  &:active {
    outline: 0;
    border: 1px solid #bbb;
  }
}
