.button {
  border: none;
  width: 140px;
  height: 44px;
  text-transform: capitalize;
  color: #000;
  font-family: Rajdhani, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: block;

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-right: 15px;

    @media screen and (min-width: 1368px) {
      margin-right: 10px;
    }

    @media screen and (min-width: 1440px) {
      margin-right: 15px;
    }
  }

  @media screen and (min-width: 1368px) {
    height: 40px;
    width: 100px;
    font-size: 14px;
  }

  @media screen and (min-width: 1440px) {
    height: 44px;
    width: 140px;
    font-size: 16px;
  }
}

.buttonDefault {
  color: #222;
  background-color: #dedede;
}

.buttonSubmit {
  background-color: #82bc2f;
}

.buttonCancel {
  background-color: #f3634e;
}
