.thanks {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 44px;
  width: 295px;
}

.poll {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.pollOptions {
  display: flex;
}
